import { Link as DDSLink, LinkProps as DDSLinkProps } from '@norges-domstoler/dds-components';
import { useLinkClickHandler } from 'react-router-dom';
import React from 'react';
import { getRelativeUrl } from '../Utils';
import { isStringURLAnExternalURL } from '../Utils';

type LinkProps = Omit<DDSLinkProps, 'htmlProps'>;

export default function Link(props: LinkProps) {
    const { children, href, ...rest } = props;
    const url = getRelativeUrl(href);
    const linkClickHandler = useLinkClickHandler(url);

    const isExternalUrl = isStringURLAnExternalURL(href);

    return (
        <DDSLink href={isExternalUrl ? href : url} onClick={isExternalUrl ? undefined : linkClickHandler} {...rest}>
            {children}
        </DDSLink>
    );
}
